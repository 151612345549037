<!--
 * @Author: your name
 * @Date: 2020-04-23 16:12:30
 * @LastEditTime: 2021-03-23 10:06:06
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/useHelp/index.vue
-->

<template>
  <div class="home">
    <Banner />
    <!-- 产品展示 -->
    <section class="product-display">
      <div class="top">
        <h3>
          <span>产品展示</span>
        </h3>
        <router-link class="more" to="/desk/product">
          更多>
        </router-link>
      </div>
      <div class="lists">
        <section class="content-desk">
          <router-link class="list-single" to="/desk/product">
            <div class="scale-box">
              <img src="../../assets/images/home/pr2.png">
            </div>
            <div class="title">
              <h4>课桌先生智能家教学习桌卓越系列</h4>
              <div class="keyword-lists">
                <span>收纳性强</span>
                <span>性价比高</span>
              </div>
            </div>
          </router-link>
          <router-link class="list-single" to="/desk/product">
            <div class="scale-box">
              <img src="../../assets/images/home/pr1.png">
            </div>
            <div class="title">
              <h4>课桌先生智能家教学习桌创想系列</h4>
              <div class="keyword-lists">
                <span>收纳性强</span>
                <span>性价比高</span>
              </div>
            </div>
          </router-link>
          <router-link class="list-single" to="/desk/product">
            <div class="scale-box">
              <img src="../../assets/images/home/pr3.png">
            </div>
            <div class="title">
              <h4>青少年可升降矫姿椅</h4>
              <div class="keyword-lists">
                <span>收纳性强</span>
                <span>性价比高</span>
              </div>
            </div>
          </router-link>
        </section>
      </div>
    </section>
    <!-- pc加盟板块 -->
    <section class="join-in">
      <div class="center">
        <div class="left">
          <video id="movie" poster="../../assets/images/home/joinVid.png" src="https://cunw-edu-live-static.oss-cn-hangzhou.aliyuncs.com/show/%E8%AF%BE%E6%A1%8C%E5%85%88%E7%94%9F%E7%AE%80%E6%B4%81%E7%89%88.m4v" controls="controls" />
          <!-- <img src="../../assets/images/home/joinVid.png"> -->
        </div>
        <div class="right">
          <div class="patent">
            <div class="dg">
              <h4>创新性智能家教学习桌，开启智慧学习新时代</h4>
            </div>
          </div>
          <p class="js">
            我们赋予了当今书桌更多的使命，创造出智能家教学习桌——课桌先生。将智能学习机与人体工学课桌巧妙融合，其优势表现为：以数据中台集中调控，系统内部融合三大核心体系，
            即丰富的教学资源，增强学习的趣味性，拓宽学生视野；在线学习网校，提供更优质的教学资源、更美好的教学体验；线上商城，汇聚全球文创佳品，打造专属青少年的学习乐园。
          </p>

          <div class="contect">
            <div class="add" @click="linlJoin()">
              了解加盟政策
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 移动端加盟 -->
    <section class="join-in-mb">
      <div class="patent">
        <h4>创新性智能家教学习桌</h4>
        <h4>开启智慧学习新时代</h4>
      </div>
      <div class="left">
        <video id="movie" poster="../../assets/images/home/joinVid.png" src="https://cunw-edu-live-static.oss-cn-hangzhou.aliyuncs.com/show/%E8%AF%BE%E6%A1%8C%E5%85%88%E7%94%9F%E7%AE%80%E6%B4%81%E7%89%88.m4v" controls="controls" />
        <!-- <img src="../../assets/images/home/joinVid.png"> -->
      </div>
      <p class="js">
        我们赋予了当今书桌更多的使命，创造出智能家教学习桌——课桌先生。将智能学习机与人体工学课桌巧妙融合，其优势表现为：以数据中台集中调控，系统内部融合三大核心体系，
        即丰富的教学资源，增强学习的趣味性，拓宽学生视野；在线学习网校，提供更优质的教学资源、更美好的教学体验；线上商城，汇聚全球文创佳品，打造专属青少年的学习乐园。
      </p>

      <div class="add" @click="linlJoin()">
        点击咨询加盟
      </div>
    </section>
    <!-- 新闻资讯 -->
    <!-- <section class="product-display">
      <div class="top">
        <h3>
          News Information
          <span>
            新闻资讯
          </span>
        </h3>
        <a class="more">
          more>
        </a>
      </div>
      <div class="lists">
        <router-link class="news-special" to="/news/previewNews">
          <div class="scale-box">
            <img src="../../assets/images/home/news1.png">
          </div>

          <div class="title">
            <h4>热烈祝贺优智多第1942家石家庄海悦天</h4>
            <div class="keyword-lists">
              7月21日，湖南省人民政府副省长陈飞莅临新...
            </div>
          </div>
        </router-link>
        <router-link class="news-special" to="/news/previewNews">
          <div class="scale-box">
            <img src="../../assets/images/home/news2.png">
          </div>
          <div class="title">
            <h4>中国消费新闻网 | 品质安全与315无关...</h4>
            <div class="keyword-lists">
              7月21日，湖南省人民政府副省长陈飞莅临新...
            </div>
          </div>
        </router-link>
        <router-link class="news-special" to="/news/previewNews">
          <div class="scale-box">
            <img src="../../assets/images/home/news3.png">
          </div>
          <div class="title">
            <h4>优智多商城致力于汇聚全球文创佳品</h4>
            <div class="keyword-lists">
              以器载道，以物传情，聚焦青少年对学习和生活的美好追求，为顾客提供优质好物与贴心服务。
            </div>
          </div>
        </router-link>
        <router-link class="news-special" to="/news/previewNews">
          <div class="scale-box">
            <img src="../../assets/images/home/news4.png">
          </div>
          <div class="title">
            <h4>热烈祝贺优智多第1941家保定万博孩子...</h4>
            <div class="keyword-lists">
              5月23日上午，湖南省委常委、长沙市委书记...
            </div>
          </div>
        </router-link>
      </div>
    </section> -->
  </div>
</template>

<script>
import Banner from '@/components/baseComon/banner.vue';

export default {
  name: 'OS',
  components: { Banner },

  data() {
    return {
    };
  },
  mounted() {
    if (this.$route.query.from === 'ai') document.title = '课桌先生';
  },
  methods: {
    linlJoin() {
      this.$router.push('/joinPromotion');
    }
  }
};
</script>

<style lang="scss">
@media screen and (min-width: 1400px) {
  .home{
    width: 100%;
    height: auto;
    background: #fff;
    // 产品展示，新闻资讯
    .product-display{
      width: 1400px;
      height: auto;
      overflow: hidden;
      margin: 0 auto;
      padding: 86px 0 100px;
      .top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #8A8684;
        font-size: 24px;
        h3{
          span{
            font-weight: 600;
            color: #41B1E6;
          }
        }
        .more{
          font-size: 14px;
          color: #313131;
        }
      }
      // 产品展示
      .lists{
        width: 100%;
        .content-desk{
          display: flex;
          justify-content: space-between;
        }
        .list-single{
          width: 30%;
          .scale-box{
            width: 100%;
            max-height: 443px;
            overflow: hidden;
            margin: 42px 0;
            img{
              width: 100%;
              height: auto;
              transition:all 0.5s;
              &:hover{
                transform: scale(1.1);  /*以y轴为中心旋转*/
              }
            }
          }

          .title{
            color: #313131;
            font-size: 18px;
            border-left: 6px solid #41B1E6;
            padding-left:10px;
            h4{
              font-weight: 100;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              &:hover{
                color: #41B1E6;
              }
            }
            .keyword-lists{
              display: flex;
              margin-top: 14px;
              span{
                display: inline-block;
                text-align: center;
                line-height: 35px;
                width:100px;
                height:35px;
                border:1px solid rgba(191,191,191,1);
                margin-right: 10px;
                color: #BFBFBF;
              }
            }
          }
        }
      }
      // 新闻资讯
      .news-special{
        width: 23%;
        .scale-box{
          width: 100%;
          max-height: 345px;
          overflow: hidden;
          margin: 42px 0;
          img{
            width: 100%;
            height: auto;
          }
        }

        .title{
          color: #313131;
          h4{
            font-weight: 600;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            font-size: 18px;
          }
          .keyword-lists{
            margin-top: 12px;
            color: #959595;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            font-size:16px;
          }
        }
        &:hover{
          h4{
            color: #41B1E6;
          }
        }
      }
    }
    // 加盟板块
    .join-in{
      width: 100%;
      height: 612px;
      background: #EFF7FF;
      .center{
        width: 1400px;
        height: 100%;
        overflow: hidden;
        margin: 0 auto;
        padding: 120px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          width:721px;
          // height:470px;
          video{
            width: 100%;
          }
        }
        .right{
          width: 600px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 470px;
          .patent{
            display: flex;
            justify-content: space-between;
            .dg{
              h4{
                font-size: 32px;
                color: #41B1E6;
              }
            }
          }
          .js{
            font-size:18px;
            line-height:32px;
            margin: 22px 0 36px;
            text-indent: 36px;
            text-align: justify;
          }
          .contect{
            .add{
              width:313px;
              height:80px;
              background:#41B1E6;
              border-radius:40px;
              text-align: center;
              line-height: 80px;
              font-size:32px;
              color: #fff;
              cursor: pointer;
            }
          }
        }
      }
    }
    .join-in-mb{
      display: none;
    }
    .youzhiduo-app-p{
      width: 100%;
      height: auto;
      overflow: hidden;
      display: flex;
      justify-content: center;
      img{
        width: 100%;
        margin: 0 auto;
        height: auto;
      }
    }

  }
}
@media screen and (max-width: 1400px) {
  .home{
    background: #fff;
    .product-display{
      width: 100%;
      padding: .8rem 0;
      box-sizing: border-box;
      .top{
        padding: 0 .53rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #8A8684;
        font-size: .747rem;
        h3{
          span{
            font-weight: 600;
            color: #41B1E6;
          }
        }
        .more{
          font-size: .373rem;
          color: #49454D;
        }
      }
      .lists{
        width: 100%;
        overflow-x: auto;
        padding: 0 .53rem;
        .content-desk{
          width: 19.884rem;
          display: flex;
          .list-single{
            width: 6.13rem;
            margin-right: .747rem;
            .scale-box{
              width: 100%;
              max-height: 6.13rem;
              overflow: hidden;
              img{
                width: 100%;
                height: auto;
              }
            }
            &:nth-of-type(3){
              margin-right: 0;
            }
            .title{
              font-size: .373rem;
              border-left: 3px solid #41B1E6;
              padding-left:.133rem;
              margin-top: .48rem;
              h4{
                font-weight: 100;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                color: rgba(151, 145, 145, 1);
              }
              .keyword-lists{
                display: none;
              }
            }
          }
        }
      }
    }
    .join-in-mb{
      width: 100%;
      padding: 0 .53rem;
      overflow: hidden;
      margin-top: .2rem;
      .patent{
        color: #27A5E2;
        font-size: .747rem;
      }
      .left{
        margin-top: .48rem;
        img{
          width: 100%;
          height: auto;
        }
        video{
          width: 100%;
        }
      }
      .js{
        font-size: .373rem;
        line-height: .747rem;
        color: #979191;
        text-indent:.746rem ;
        margin-top: .48rem;
        text-align: justify;
      }
      .add{
        margin: .8rem 0;
        width: 100%;
        height: 1.6rem;
        background: #27A5E2;
        border-radius: 1.07rem;
        color: #fff;
        font-size: .693rem;
        font-weight: 600;
        text-align: center;
        line-height: 1.6rem;
      }
    }
    .join-in{
      display: none;
    }
  }

}
</style>
