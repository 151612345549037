<!--
 * @Author: your name
 * @Date: 2020-05-28 10:17:46
 * @LastEditTime: 2021-01-06 20:00:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/components/baseComon/banner.vue
-->
<template>
  <section class="desk-common-banner">
    <!-- pc -->
    <el-carousel class="banner-pc" trigger="click" arrow="never" :interval="5000">
      <el-carousel-item v-for="(item,index) in bannerListPc" :key="index">
        <img :src="item.imgUrl" class="banner-img" @click="linkToProList(item.linkUrl)">
      </el-carousel-item>
    </el-carousel>
    <!-- mb -->
    <el-carousel class="banner-mb" trigger="click" arrow="never" :interval="5000">
      <el-carousel-item v-for="(item,index) in bannerListMb" :key="index">
        <img :src="item.imgUrl" class="banner-img" @click="linkToProList()">
      </el-carousel-item>
    </el-carousel>
  </section>
</template>

<script>

export default {
  name: 'Banner',
  data() {
    return {
      bannerListPc: [
        {
          imgUrl: require('../../assets/images/home/banner1.png')
        },
        {
          imgUrl: require('../../assets/images/home/banner2.jpeg'),
          linkUrl: 'https://www.uaregood.net/category.php?id=1458'
        }
      ],
      bannerListMb: [
        {
          imgUrl: require('../../assets/images/moblie/banner2.jpg')
        },
        {
          imgUrl: require('../../assets/images/moblie/banner1.jpg')
        }
      ]
    };
  },
  methods: {
    linkToProList(linkUrl) {
      if (linkUrl) {
        window.location = linkUrl;
      } else {
        this.$router.push('/desk/product');
      }
    }
  }
};
</script>
<style lang="scss" scope>
@media screen and (min-width: 1200px) {
  .desk-common-banner {
    width: 100%;
    // max-width: 1920px;
    height: auto;
    min-height: 600px;
    margin: 0 auto;
    .banner-img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
  .banner-mb{
    display: none;
  }
  .el-carousel__container{
    width: 100%;
    height: auto;
    min-height: 600px;
  }
}
@media screen and (min-width:993px) and (max-width: 1200px){
  .desk-common-banner{
    .el-carousel__container{
      height: auto !important;
      min-height: 14.32rem !important;
    }
}
  }

@media screen and (max-width: 1200px) {
  .desk-common-banner {
    width: 100%;
    height: auto;
    margin: 0 auto;
    .banner-img{
      width: 100%;
      height: 100%;
    }
    .el-carousel__container{
      width: 100%;
      height: auto;
      min-height: 14.32rem !important;
    }
  }
  .banner-pc{
    display: none;
  }

}
</style>
